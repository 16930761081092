export const validNumber = function (val) {
  // 验证为非负整数 >=0
  const reg = "^\\d+$";
  let R = new RegExp(reg);
  return R.test(val);
};

export const validFloatNumber = function (val) {
  // >=0的小数
  // const regEx = new RegExp('^[0-9]+(.[0-9]{1,2}){0, 1}$')
  // const regEx = new RegExp('/^(([1-9][0-9]*)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/')
  const regEx = /^(\d+|\d+\.\d{1,2})$/;
  return regEx.test(val);
};

export const validPhone = function (val) {
  const regExp = /^1[3|4|5|7|8][0-9]{9}$/;
  return regExp.test(val);
};
