<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="添加巡检员"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 6 }">
      <a-form-item label="人员姓名" v-bind="validateInfos.name">
        <a-input
          v-model:value="pdaUserVo.name"
          :maxlength="16"
          placeholder="人员姓名"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="用户编号" v-bind="validateInfos.code">
        <a-input
          v-model:value="pdaUserVo.code"
          :maxlength="16"
          placeholder="用户编号"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="联系方式" v-bind="validateInfos.phone">
        <a-input
          v-model:value="pdaUserVo.phone"
          :maxlength="11"
          placeholder="联系方式"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="使用PDA编码" v-bind="validateInfos.pdaId">
        <a-select
          v-model:value="pdaUserVo.pdaId"
          notFoundContent="暂无数据"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            v-for="item in pdaList"
            :key="item.id"
            :value="item.id"
            >{{ item.code }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="终端登录账号" v-bind="validateInfos.account">
        <a-input
          v-model:value="pdaUserVo.account"
          :maxlength="16"
          placeholder="终端登录密码"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="终端登录密码" v-bind="validateInfos.password">
        <a-input-password
          v-model:value="pdaUserVo.password"
          :maxlength="16"
          placeholder="终端登录密码"
          allow-clear
        ></a-input-password>
      </a-form-item>
      <a-form-item label="所属车场" v-bind="validateInfos.parkingLotIds">
        <a-select
          mode="multiple"
          v-model:value="pdaUserVo.parkingLotIds"
          notFoundContent="暂无数据"
          placeholder="请选择"
          allow-clear
        >
          <a-select-option
            v-for="item in parkingLotList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="状态" v-bind="validateInfos.state">
        <a-radio-group v-model:value="pdaUserVo.state">
          <a-radio
            v-for="item in pdaUserStates"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { save } from "@/api/maintenance/pdaUserApi";
import usePdaUser from "../../../../hooks/maintenance/usePdaUser";
import { validPhone } from "../../../../utils/validateUtils";
import md5 from "js-md5";
import config from "../../../../config";
const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const {
      getParkingLotList,
      parkingLotList,
      pdaUserVo,
      pdaUserStates,
      getPdaList,
      pdaList,
    } = usePdaUser();
    const { pdaUserVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(pdaUserVo, pdaUserVoRules);
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        if (!validPhone(pdaUserVo.phone)) {
          await showTipMessage("请填写正确的联系方式", "error");
          return;
        }
        let action = await showConfirm("添加新的巡检员?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              name: pdaUserVo.name,
              code: pdaUserVo.code,
              phone: pdaUserVo.phone,
              account: pdaUserVo.account,
              pdaId: pdaUserVo.pdaId,
              password: pdaUserVo.password,
              state: pdaUserVo.state,
              parkingLotIds: pdaUserVo.parkingLotIds,
            };
            await save(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      getParkingLotList();
      getPdaList();
    });
    return {
      ...toRefs(pros),
      disabled,
      visible,
      afterClose,
      getParkingLotList,
      parkingLotList,
      pdaUserVo,
      pdaUserStates,
      validateInfos,
      doSubmit,
      getPdaList,
      pdaList,
    };
  },
};
</script>

<style scoped></style>
