import { reactive, ref } from "vue";
import { list } from "@/api/maintenance/pdaUserApi";
import useMessage from "@/hooks/useMessage";
import { listAll } from "../../api/parking/parkingLotApi";
import { listAllPda } from "../../api/maintenance/pdaApi";
const usePdaUser = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingLotList = reactive([]);
  const pdaList = reactive([]);
  const pdaUserStates = ref([
    { value: 1, label: "在职" },
    { value: 0, label: "离职" },
  ]);
  const pdaUserVo = reactive({
    name: "",
    code: "",
    phone: "",
    account: "",
    pdaId: null,
    password: "",
    state: 1,
    parkingLotIds: [],
  });

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "姓名",
      align: "center",
      dataIndex: "name",
    },
    {
      title: "用户编号",
      align: "center",
      dataIndex: "code",
    },
    {
      title: "联系方式",
      align: "center",
      dataIndex: "phone",
    },
    {
      title: "终端使用账号",
      align: "center",
      dataIndex: "account",
    },
    {
      title: "关联车场",
      align: "center",
      dataIndex: "parkingLotNames",
    },
    {
      title: "pda编号",
      align: "center",
      dataIndex: "pdaCode",
    },
    {
      title: "人员状态",
      align: "center",
      dataIndex: "state",
      slots: { customRender: "state" },
    },
    {
      title: "操作",
      align: "center",
      width: "240px",
      slots: { customRender: "operation" },
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    try {
      let res = await list(params, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const getParkingLotList = async () => {
    try {
      let { data } = await listAll();
      parkingLotList.push(...data);
    } catch (e) {
      showErr(e);
    }
  };

  const getPdaList = async () => {
    try {
      let { data } = await listAllPda();
      pdaList.push(...data);
    } catch (e) {
      showErr(e);
    }
  };

  const params = reactive({
    name: "",
    phone: "",
    parkingLotId: null,
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    getParkingLotList,
    pdaUserVo,
    parkingLotList,
    pdaList,
    getPdaList,
    pdaUserStates,
  };
};

export default usePdaUser;
