import axios from "axios";

export const list = (params, page, pageSize) => {
  return axios.get("/pdaUser/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

export const save = vo => {
  return axios.post("/pdaUser/add", vo);
};

export const detail = id => {
  return axios.get(`/pdaUser/${id}/detail`);
};

export const edit = (id, vo) => {
  return axios.put(`/pdaUser/${id}/edit`, vo);
};

export const deletePdaUser = id => {
  return axios.delete(`/pdaUser/${id}/del`);
};
